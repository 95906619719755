import React, { useEffect, useState } from "react";
import "./index.css";
import Dropdown from "../../Components/Atoms/Dropdown";
import AddIcon from "@mui/icons-material/Add";

import CustomButton from "../../Components/Atoms/CustomButtoncon";
import Model from "../../Components/Organisms/ModelComponent";
import AddCustomer from "./coms/AddCustomer";
import TableButton from "../../Components/Molecules/TableButton";
import { TableIconConstant, sortList } from "../../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompaniesListRequest,
  postCompaniesResponseClear,
  putCompaniesResponseClear,
} from "../../Redux/Companies/CompaniesActions";
import toast from "react-hot-toast";
import NoRecords from "../../Components/Molecules/NoRecords";
import HelmetData from "../../Utils/HelmetData";
import CountPerPage from "../../Components/Organisms/CountperPage";
import AddCompanies from "./coms/AddCustomer";
import { useNavigate } from "react-router-dom";

const Companies = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [each, setEach] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalpage, setTotalpage] = useState(1);
  const [pagelimit, setPagelimit] = useState(10);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open) return;
    setEach(null);
  }, [open]);
  const {
    postCompaniesResponse,
    postCompaniesLoading,
    getCompaniesResponse,
    putCompaniesLoading,
    putCompaniesResponse,
  } = useSelector((state) => state.companies);
  useEffect(() => {
    if (!getCompaniesResponse) return;

    setTotalpage(getCompaniesResponse?.data?.total_pages);
  }, [getCompaniesResponse]);
  useEffect(() => {
    if (putCompaniesLoading || postCompaniesLoading) return;

    dispatch(
      getCompaniesListRequest({
        quickFilter,
        filter: { sortBy, page },
        keyword: "",
      })
    );
  }, [putCompaniesLoading, postCompaniesLoading, page, pagelimit, sortBy]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!putCompaniesResponse) return;
    if (putCompaniesResponse?.type === "success") {
      toast.success("Company Updated Successfully");
      handleClose();
    }
    dispatch(putCompaniesResponseClear());
  }, [putCompaniesResponse]);
  useEffect(() => {
    if (!postCompaniesResponse) return;
    if (postCompaniesResponse?.type === "success") {
      toast.success("Company Created Successfully");
      handleClose();
    }

    dispatch(postCompaniesResponseClear());
  }, [postCompaniesResponse]);

  return (
    <>
      <HelmetData title="Companies" />
      <Model
        handleClose={handleClose}
        open={open}
        Title={each ? "Edit Company" : "Add New Company"}
      >
        <AddCompanies each={each} handleClose={handleClose} />
      </Model>
      <div className="matrial_body">
        {" "}
        <div className="filter_table_container">
          <div></div>
          <div className="sort_by_filter">
            <p>Sort By</p>{" "}
            <div className="filter_input">
              <Dropdown
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
                name="sortBy"
                setValue="value"
                placeholder="Select"
                data_testid="role"
                required="true"
                data={sortList}
              />
            </div>
            <CustomButton
              size="medium"
              btnType={"primary"}
              buttonType={"add"}
              btnClass={"py-10 "}
              onClick={() => {
                handleOpen();
              }}
            >
              Add New Company
            </CustomButton>
          </div>
        </div>
        <div className="tableWrpr">
          <table data-testid="table-container" className="table-container">
            <tbody>
              <tr className="td_header_color">
                <th className="th">Sr.No</th>
                <th className="th">Company Name</th>
                <th className="th">User Count</th>
                <th className="th">Phone Number</th>
                <th className="th">Email </th>
                <th className="th">Actions</th>
              </tr>

              {console.log(getCompaniesResponse, "uyuyu")}
              {getCompaniesResponse?.data?.length ? (
                getCompaniesResponse?.data?.map((each, index) => {
                  return (
                    <tr className="tr-container" key={index}>
                      <td className="td td-index">{index + 1}</td>
                      <td className="td">{each?.name}</td>
                      <td className="td">{each?.user_count}</td>
                      <td className="td">{each?.phone}</td>
                      <td className="td">{each?.email}</td>

                      <td className="td">
                        <div className="td-actions">
                          <TableButton
                            buttonType={TableIconConstant.view}
                            onClickBtn={() => {
                              navigate(`${each._id}`);
                            }}
                            size="large"
                          />

                          <TableButton
                            buttonType={TableIconConstant.edit}
                            onClickBtn={
                              () => {
                                setEach(each);
                                handleOpen();
                              }
                              // navigateEdit(each._id)
                            }
                            size="large"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecords />
              )}
            </tbody>
          </table>
        </div>
        <CountPerPage
          pagelimit={pagelimit}
          totalpage={totalpage}
          setPagelimit={setPagelimit}
          setPage={setPage}
        />
      </div>
    </>
  );
};

export default Companies;
