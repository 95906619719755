import React, { useEffect, useRef, useState } from "react";
import "./AddCompanies.css";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import CustomCancelSubmit from "../../../Components/Molecules/CustomCancelSubmit";
import InputField from "../../../Components/Atoms/InputField";
import {
  postCompaniesRequest,
  putCompaniesRequest,
} from "../../../Redux/Companies/CompaniesActions";
import {
  expression,
  stateList,
  countryList,
  imageFileFormat,
} from "../../../Utils/constants";
import PhoneNumber from "../../../Components/Molecules/Phonenumber";
import Dropdown from "../../../Components/Atoms/Dropdown";
import userdummy from "../../../asset/gway-logo-login.png";
import { appUrl } from "../../../Utils/axios";

function AddCompanies(props) {
  const dispatch = useDispatch();

  let [stateListUser, setStateListUser] = useState([]);
  const [checkphone, setCheckphone] = useState(0);
  const { each } = props;
  const [image, setImage] = useState(userdummy);
  const hiddenFileInput = useRef(null);
  const handleChange = (e) => {
    const filename = e?.currentTarget?.files[0]?.name?.split(".");
    if (filename && filename[0]) {
      const format = filename[filename?.length - 1];
      if (imageFileFormat.includes(format)) {
        formik.setFieldValue("profile", e.currentTarget.files[0]);
        setImage(URL.createObjectURL(e.currentTarget.files[0]));
      }
    }
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  useEffect(() => {
    if (!each) return;
    const {
      calculation_settings: { rent_type, rounded_off, price_editable },
    } = each;
    for (let key in each) {
      formik.setFieldValue(key, each[key]);
    }
    formik.setFieldValue("rent_type", rent_type);
    formik.setFieldValue("rounded_off", rounded_off);
    formik.setFieldValue("price_editable", price_editable);
    if (each?.profile) {
      setImage(appUrl + "/" + each?.profile);
    }
  }, [each]);
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      profile: "",
      email: "",
      country: "",
      address: "",
      state: "",
      city: "",
      zipcode: "",
      user_count: 1,
      rent_type: "hours",
      rounded_off: "exclude",
      price_editable: true,
    },
    onSubmit: (values) => {
      if (each) {
        dispatch(putCompaniesRequest(values, each._id));
      } else {
        dispatch(postCompaniesRequest(values));
      }
      console.log(values, "yyy");
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .matches(expression.email, "Invalid email")
        .matches(expression.Without_space, "Email should not contain spaces"),
      name: Yup.string()
        .required("Required")
        .min(3, "Name must be at least 3 characters")
        .max(50, "Name must be at most 50 characters")
        .matches(expression.char, "name should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "name should not contain spaces"),
      company_name: Yup.string()

        .min(3, "must be at least 3 characters")
        .max(50, "must be at most 50 characters")
        .matches(expression.char, "company name should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "company name should not contain spaces"),
      address: Yup.string()

        .min(3, " must be at least 3 characters")
        .max(50, "must be at most 50 characters")
        .matches(expression.min_one_char, "should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "should not contain spaces"),
      city: Yup.string()

        .min(3, "must be at least 1 characters")
        .max(50, "must be at most 50 characters")
        .matches(expression.char, "city should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "city  should not contain spaces"),
      phone: Yup.string().test(
        "check phone",
        "Phone number is invalid",
        (hour) => {
          return !(hour && checkphone !== hour.length);
        }
      ),
    }),
  });
  useEffect(() => {
    if (formik.values?.country) {
      const selectedCountry = countryList.find(
        (each) => each.name === formik.values?.country
      );
      let updatedStateListUser = stateList.getStatesOfCountry(
        selectedCountry?.isoCode
      );
      setStateListUser(updatedStateListUser);
      formik.setFieldValue("state", each?.state);
    }
  }, [formik.values.country]);
  return (
    <>
      <form className="customer_model_form">
        <div className="black-ine-container"></div>
        <div className="customer_file_upload">
          <div
            className="image_customer_container_admin "
            onClick={handleClick}
          >
            {image ? (
              <img className="company_logo" src={image} />
            ) : (
              <AccountCircleIcon sx={{ fontSize: "101px" }} className="" />
            )}
            <input
              type="file"
              onChange={handleChange}
              ref={hiddenFileInput}
              style={{ display: "none" }}
              accept="image/jpg,image/png,image/jpeg"
            />
            {/* <div className="customer_upload_button" onClick={handleClick}>
              Upload
            </div> */}
          </div>
        </div>

        <InputField
          value={formik.values.name}
          onChange={formik.handleChange}
          name="name"
          labelText="Name"
          placeholder="Enter Name"
          required={true}
          onBlur={formik.handleBlur}
          errorMsg={formik.touched.name && formik.errors?.name}
          type="text"
        />
        <div className="col_2">
          <InputField
            value={formik.values.user_count}
            onChange={(e) => {
              if (e.target.value < 0) {
                return;
              }
              formik.handleChange(e);
            }}
            name="user_count"
            labelText="User Count"
            placeholder="Enter User Count"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.user_count && formik.errors?.user_count}
            type="Number"
          />
          <PhoneNumber
            value={formik.values.phone}
            onChange={formik.setFieldValue}
            labelText="Phone Number"
            name="phone"
            placeholder="Enter phone number"
            onBlur={formik.handleBlur}
            validcheck={setCheckphone}
            errorMsg={formik.touched.phone && formik.errors?.phone}
          />
          <InputField
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
            labelText="Email Address"
            placeholder="Enter Email"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.email && formik.errors?.email}
            type="email"
          />
          <InputField
            value={formik.values.gst}
            onChange={formik.handleChange}
            name="gst"
            labelText="GST"
            placeholder="Enter Gst"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.gst && formik.errors?.gst}
            type="text"
          />
          <Dropdown
            value={formik.values.rent_type}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue("rent_type", e.target.value);
            }}
            labelText="Calculation Type"
            placeholder="Select Type"
            name="rent_type"
            data_testid="rent_type"
            errorMsg={formik.touched.rent_type && formik.errors?.rent_type}
            onBlur={formik.handleBlur}
            data={[
              { name: "Hours", value: "hours" },
              { name: "Days", value: "days" },
            ]}
          />
          <Dropdown
            value={formik.values.rounded_off}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue("rounded_off", e.target.value);
            }}
            labelText="Round Off"
            placeholder="Select Method"
            name="rounded_off"
            data_testid="rounded_off"
            errorMsg={formik.touched.rounded_off && formik.errors?.rounded_off}
            onBlur={formik.handleBlur}
            data={[
              { name: "Exclude", value: "exclude" },
              { name: "Include", value: "include" },
            ]}
          />
          <Dropdown
            value={formik.values.price_editable.toString()}
            onChange={(e) => {
              const booleanValue = e.target.value === "true";
              formik.setFieldValue("price_editable", booleanValue);
            }}
            labelText="Price Editable"
            placeholder="Select value"
            name="price_editable"
            data_testid="price_editable"
            errorMsg={Boolean(
              formik.touched.price_editable && formik.errors?.price_editable
            )}
            onBlur={formik.handleBlur}
            data={[
              { name: "Edit", value: "true" },
              { name: "Fixed", value: "false" },
            ]}
          />
        </div>

        <InputField
          value={formik.values.address}
          onChange={formik.handleChange}
          name="address"
          labelText="Address"
          placeholder="Enter address"
          onBlur={formik.handleBlur}
          errorMsg={formik.touched.address && formik.errors?.address}
          type="text"
        />
        <div className="col_2">
          <Dropdown
            value={formik.values.country}
            onChange={formik.handleChange}
            labelText="Country"
            name="country"
            placeholder="Select your country"
            setValue="name"
            data_testid="country"
            errorMsg={formik.touched.country && formik.errors?.country}
            onBlur={formik.handleBlur}
            data={countryList}
          />

          <Dropdown
            value={formik.values.state}
            onChange={formik.handleChange}
            labelText="State"
            name="state"
            placeholder="Select your state"
            setValue="name"
            data_testid="state"
            errorMsg={formik.touched.state && formik.errors?.state}
            onBlur={formik.handleBlur}
            data={stateListUser}
          />
          <InputField
            value={formik.values.city}
            onChange={formik.handleChange}
            name="city"
            labelText="City"
            placeholder="Enter City"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.city && formik.errors?.city}
            type="text"
          />
          <InputField
            value={formik.values.zipcode}
            onChange={formik.handleChange}
            name="zipcode"
            labelText="Zipcode"
            placeholder="Enter Zipcode"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.zipcode && formik.errors?.zipcode}
            type="number"
          />
        </div>
        <div className="black-ine-container"></div>
      </form>
      <CustomCancelSubmit
        cancelLabel="Cancel"
        submitLabel={each ? "Update Company" : "Add Company"}
        handleClose={props.handleClose}
        handleSubmit={() => formik.handleSubmit()}
      />
    </>
  );
}

export default AddCompanies;
