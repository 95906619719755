import React from "react";
import "./index.css";

export default function NoRecords(props) {
  return (
    <tr>
      <td colSpan={props.length ? props.length : "100"}>
        <p className="table-not-found-para1">
          {props.message ? props.message : "No Record Found"}
        </p>
      </td>
    </tr>
  );
}
